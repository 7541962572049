<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'"> 
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
            {{
              $t("message.edit_m", {
                m: $t("message.skill_y"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <div class="app-modal__body p-5 pb-0">
        <div
          class="timeline-items__right rounded-sm w-100 p-4"
          v-loading="loadingData" :class="mode ? 'table__myday' : 'table__mynight'"
        >
          <el-form ref="form" status-icon :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.name") }}
                  </span>
                  <el-form-item prop="name">
                    <el-input
                      size="medium"
                      v-model="form.name"
                      autocomplete="off"
                      :class="mode ? 'input__day' : 'input__night'"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.gender") }}
                  </span>
                  <el-form-item prop="gender_ids">
                    <select-gender
                      :id="form.gender_ids"
                      :size="'medium'"
                      :multiple="true"
                      :placeholder="columns.gender_ids.title"
                      v-model="form.gender_ids"
                    >
                    </select-gender>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.company") }}
                  </span>
                  <el-form-item prop="company_id">
                    <select-company
                      :size="'medium'"
                      :id="form.company_id"
                      :placeholder="columns.company_id.title"
                      v-model="form.company_id"
                    >
                    </select-company>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.branch") }}
                  </span>
                  <el-form-item prop="branch_id">
                    <select-branch
                      :company_id="form.company_id"
                      :id="form.branch_id"
                      :size="'medium'"
                      :placeholder="columns.branch_id.title"
                      v-model="form.branch_id"
                    >
                    </select-branch>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.department") }}
                  </span>
                  <el-form-item prop="department_ids">
                    <select-department
                      :branch_id="form.branch_id"
                      :id="form.department_ids"
                      :size="'medium'"
                      :multiple="true"
                      :placeholder="columns.department_ids.title"
                      v-model="form.department_ids"
                    >
                    </select-department>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.requirements") }}
                  </span>
                  <el-form-item prop="requirement_ids">
                    <select-requirement
                      :size="'medium'"
                      :placeholder="columns.requirement_ids.title"
                      v-model="form.requirement_ids"
                      :id="form.requirement_ids"
                      :multiple="true"
                    >
                    </select-requirement>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-0">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.age") }}
                  </span>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item prop="min_age">
                        <el-input-number
                          v-model="form.min_age"
                          controls-position="right"
                          :min="18"
                          :max="100"
                          size="medium"
                          class="w-100"
                          :class="mode ? 'input__day' : 'input__night'"
                        ></el-input-number>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item prop="max_age">
                        <el-input-number
                          v-model="form.max_age"
                          controls-position="right"
                          :min="18"
                          :max="100"
                          size="medium"
                          class="w-100"
                          :class="mode ? 'input__day' : 'input__night'"
                        ></el-input-number>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.priority") }}
                  </span>
                  <el-form-item prop="priority_id">
                    <select-priority
                      :id="form.priority_id"
                      :size="'medium'"
                      :placeholder="columns.priority_id.title"
                      v-model="form.priority_id"
                    >
                    </select-priority>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.position") }}
                  </span>
                  <el-form-item prop="position_id">
                    <select-position
                      :id="form.position_id"
                      :size="'medium'"
                      :placeholder="columns.position_id.title"
                      v-model="form.position_id"
                    >
                    </select-position>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.graphic") }}
                  </span>
                  <el-form-item prop="graphic_ids">
                    <select-graphic
                      :id="form.graphic_ids"
                      :size="'medium'"
                      :multiple="true"
                      v-model="form.graphic_ids"
                    ></select-graphic>
                  </el-form-item>
                </div>
              </el-col>
              <!-- end col -->
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.limit") }}
                  </span>
                  <el-form-item prop="limit">
                    <el-input-number
                      v-model="form.limit"
                      controls-position="right"
                      :min="0"
                      :max="100"
                      size="medium"
                      class="w-100"
                      :class="mode ? 'input__day' : 'input__night'"
                    >
                    </el-input-number>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.employmentType") }}
                  </span>
                  <el-form-item prop="employment_type_id">
                    <select-employmentType
                      :id="form.employment_type_id"
                      :size="'medium'"
                      :placeholder="columns.employment_type_id.title"
                      v-model="form.employment_type_id"
                    >
                    </select-employmentType>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.language") }}
                  </span>
                  <el-form-item prop="language_ids">
                    <select-language
                      :id="form.language_ids"
                      :size="'medium'"
                      :multiple="true"
                      :placeholder="columns.language_ids.title"
                      v-model="form.language_ids"
                    >
                    </select-language>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.skill") }}
                  </span>
                  <el-form-item prop="skill_ids">
                    <select-skill
                      :id="form.skill_ids"
                      :size="'medium'"
                      :multiple="true"
                      :placeholder="columns.skill_ids.title"
                      v-model="form.skill_ids"
                    >
                    </select-skill>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.salary_currency") }}
                  </span>
                  <el-form-item prop="currency_ids">
                    <select-currency
                      :id="form.currency_ids"
                      :size="'medium'"
                      :multiple="true"
                      :placeholder="columns.currency_ids.title"
                      v-model="form.currency_ids"
                    >
                    </select-currency>
                  </el-form-item>
                </div>
                <div class="app-form__group">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{$t('message.salary')}} </span>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item prop="min_wage">
                        <el-input
                          :placeholder="$t('message.min')"
                          v-model="form.min_wage"
                          size="medium"
                          filterable
                          :class="mode ? 'input__day' : 'input__night'"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item prop="max_wage">
                        <el-input
                          :placeholder="$t('message.max')"
                          v-model="form.max_wage"
                          size="medium"
                          filterable
                          :class="mode ? 'input__day' : 'input__night'"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.province") }}
                  </span>
                  <el-form-item prop="region_id">
                    <select-region
                      :id="form.region_id"
                      :size="'medium'"
                      :placeholder="columns.region_id.title"
                      v-model="form.region_id"
                    >
                    </select-region>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.district") }}
                  </span>
                  <el-form-item prop="district_id">
                    <select-district
                      :id="form.district_id"
                      :region_id="form.region_id"
                      :size="'medium'"
                      :placeholder="columns.district_id.title"
                      v-model="form.district_id"
                    >
                    </select-district>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{$t('message.description')}} </span>
                  <el-input
                    type="textarea"
                    size="medium"
                    :placeholder="$t('message.description')"
                    v-model="form.description"
                    :class="mode ? 'input__day' : 'input__night'"
                  ></el-input>
                </div>
                <div class="app-form__group mb-4">
                  <el-form-item prop="status_id">
                    <select-status
                      :size="'medium'"
                      :placeholder="columns.status_id.title"
                      :table_name="'vacancies'"
                      :id="form.status_id"
                      v-model="form.status_id"
                    >
                    </select-status>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.check_level") }}
                  </span>
                  <el-switch v-model="form.check_level"> </el-switch>
                </div>
              </el-col>
              <!-- end col -->
            </el-row>
          <div v-if="form.check_level">
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.question_direction_list") }}
                  </span>
                  <el-form-item
                      prop="title"
                      class="label_mini">
                        <select-question-direction  
                          :size="'medium'"
                          :class="mode ? 'input__day' : 'input__night'"
                          :multiple="true"
                          :id="form.question_direction_ids"
                          v-model="form.question_direction_ids" >
                        </select-question-direction>
                  </el-form-item>
                </div>
            </el-col>
            <el-col :span="12">
              <div class="app-form__group mb-4">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.question_count") }}
                </span>
                <el-form-item
                    prop="title"
                    class="question_count">
                    <crm-input
                      :class="mode ? 'input__day' : 'input__night'"
                      :inputValue="form.question_count"
                      :placeholder="$t('message.question_count')"
                      :size="'medium'"
                      :type="'number'"
                      v-model="form.question_count"
                    ></crm-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="app-form__group mb-4">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.min_point") }}
                </span>
                <el-form-item
                    prop="title"
                    class="min_point">
                    <crm-input
                      :class="mode ? 'input__day' : 'input__night'"
                      :inputValue="form.min_point"
                      :placeholder="$t('message.min_point')"
                      :size="'medium'"
                      :type="'number'"
                      v-model="form.min_point"
                    ></crm-input>
                </el-form-item>
              </div>
            </el-col>
            </el-row>
          </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import selectGender from "@/components/filters/inventory/select-gender";
import selectBrench from "@/components/filters/inventory/select-branch";
import selectCompany from "@/components/filters/inventory/select-company";
import selectDepartment from "@/components/filters/inventory/select-department";
import selectRequirement from "@/components/filters/inventory/select-requirement";
import selectPriority from "@/components/filters/inventory/select-priority";
import selectPosition from "@/components/filters/inventory/select-position";
import selectGraphic from "@/components/filters/inventory/select-graphic";
import selectEmploymentType from "@/components/filters/inventory/select-employmentType";
import selectLanguage from "@/components/filters/inventory/select-language";
import selectSkill from "@/components/filters/inventory/select-skill";
import selectCurrency from "@/components/filters/inventory/select-currency";
import selectRegion from "@/components/filters/inventory/select-region";
import selectDistrict from "@/components/filters/inventory/select-district";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
export default {
  mixins: [form, drawer, show],
  name: "VacancyController",
  components: {
    selectGender,
    selectBrench,
    selectDepartment,
    selectRequirement,
    selectPriority,
    selectPosition,
    selectGraphic,
    selectEmploymentType,
    selectLanguage,
    selectSkill,
    selectCurrency,
    selectRegion,
    selectDistrict,
    selectCompany,
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "vacancy/rules",
      model: "vacancy/model",
      columns: "vacancy/columns",
      mode:'MODE'
    }),
  },
  methods: {
    ...mapActions({
      update: "vacancy/update",
      show: "vacancy/show",
    }),
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.update(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (close) this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
};
</script>

